import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="row column">
      <h1>Uh oh, we can't seem to find that page.</h1>
      <p>
        Please contact us directly so we can assist you:{" "}
        <a href="tel:401-526-0055">401-526-0055</a>
      </p>
      <p>
        You can also send us a note here:{" "}
        <Link to="/contact-us">Contact us</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
